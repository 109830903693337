<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script setup>
import { ref } from 'vue'

const chartOptions = ref({
  xAxis: {
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    crosshair: true
  },
  series: [
    {
      name: 'Tokyo1',
      type: 'line',
      data: [11,256,2,46,23] // sample data
    },
    {
      name: 'Tokyo2',
      type: 'column',
      data: [1,6,43,86,23],
    }
  ]
})

</script>
<style>
</style>
